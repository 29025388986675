import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-calculator-app-detail',
  templateUrl: './app-detail.component.html',
  styleUrls: ['./app-detail.component.scss']
})
export class CalculatorAppDetailComponent implements OnInit {

  @Input() type = 'calculate';

  appServices = [
    "รองรับรายได้ทุกประเภท",
    "อัพเดตค่าลดหย่อนล่าสุด",
    "วางแผนภาษี (ซื้อประกัน กองทุน เท่าไหร่ดี)",
  ]

  constructor() { }

  ngOnInit(): void {
  }
}
