import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'itax-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  @Input() width = '24px';
  @Input() height = '24px';
  @Input() color = 'inherit';
  @Input() name = 'sample';

  constructor() {
  }

  ngOnInit(): void {
  }

}
